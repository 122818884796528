.loader{
    border: solid 3px white;
    border-top: solid 3px rgba(0, 20, 140, 1);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    animation: spin 1.5s ease infinite;
}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }