.top-section-jobSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;

    button {
        top: 13.24px;
        left: 1278.98px;
        gap: 0px;
    }
}

.job-heading {
    font-size: 18px;
    font-weight: bold;
    color: #15649C;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    flex-grow: 1;
    padding-left: 10px;

}

.name-profile {
    display: flex;
    width: 131.44px;
    height: 36.51px;
    top: 13.24px;
    left: 1278.98px;
    gap: 0px;
    border-radius: 38px;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    color: #5E7EBD;
    background: linear-gradient(90deg, rgba(213, 223, 242, 0.5) 0%, rgba(206, 196, 232, 0.5) 100%);
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
}

.main-jobs {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.exists-jobs {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    width: 50%;
    height: 423px;
    border-radius: 26px 26px 26px 26px;
    background-color: rgba(224, 226, 237, 1);
    margin-right: 30px;
}

.heading-col {
    display: flex;
    justify-content: center;
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(23, 55, 158, 1);
}

.img1 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    background-color: rgba(255, 255, 255, 0.81);
    width: 131px;
    height: 131px;
    border-radius: 99px 99px 99px 99px;
    border: 1px 1px 1px 1px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.select-can {
    width: 100%;
    max-width: 395px;
    height: auto;
    border-radius: 22px 22px 22px 22px;
    margin-left: auto;
    padding: 10px 16px;
    margin-right: auto;
    margin-top: 30px;
    background-color: rgba(211, 215, 227, 1);
    border: none;
    color: rgba(26, 42, 136, 1);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: left;
}

.search-jobs {
    width: 387px;
    height: 39px;
    border-radius: 22px 22px 22px 22px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    background: rgba(0, 20, 140, 1);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.custom-job {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    width: 50%;
    height: 423px;
    border-radius: 26px 26px 26px 26px;
    background-color: rgba(223, 240, 232, 1);
}

.custom-search {
    display: flex;
    justify-content: center;
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(51, 182, 121, 1);
}

.img2 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    background-color: rgba(255, 255, 255, 0.81);
    width: 131px;
    height: 131px;
    border-radius: 99px 99px 99px 99px;
    border: 1px 1px 1px 1px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.candidate-select {
    width: 100%;
    max-width: 395px;
    height: auto;
    border-radius: 22px 22px 22px 22px;
    padding: 10px 16px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    background-color: rgba(206, 232, 221, 1);
    border: none;
    color: rgba(26, 136, 88, 1);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: left;
}

.next {
    width: 387px;
    height: 39px;
    border-radius: 22px 22px 22px 22px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    background: rgba(0, 140, 70, 1);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}


/* for custom-job-search page */

.custom-job-search {
    /* background-color: red; */
    width: 100rem;
    height: 40rem;
    border-radius: 26px 26px 26px 26px;
    margin: 4rem 10rem;
    background-color: rgba(242, 255, 244, 1);
}

.custom-search-heading {
    display: flex;
    justify-content: center;
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(51, 182, 121, 1);
    padding-top: 2rem;
}

.img2-custom {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    background-color: rgba(255, 255, 255, 0.81);
    width: 131px;
    height: 131px;
    border-radius: 99px 99px 99px 99px;
    border: 1px 1px 1px 1px;
    align-items: center;
    margin-left: 45rem;
}

.candidate-select-custom {
    width: 387px;
    height: 39px;
    border-radius: 22px 22px 22px 22px;
    margin-left: 39rem;
    margin-top: 30px;
    background-color: rgba(206, 232, 221, 1);
    border: none;
    color: rgba(26, 136, 88, 1);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: left;
    padding-left: 5px;
    outline: none;
}


textarea {
    border: none;
    outline: none;
    /* Remove the outline as well */
}

textarea:focus {
    border: none;
    /* Remove border on focus */
    outline: none;
    /* Remove any default outline on focus */
}

.write-content {
    margin: 2rem 12rem;
    width: 80rem;
    height: 10rem;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: rgba(0, 160, 67, 1);
    background-color: rgba(242, 242, 242, 0.25);
    border: 1px solid rgba(34, 183, 56, .1);
}

.write-content:hover {
    border: 1px solid rgba(34, 183, 56, 1);
}


.compare-btn {
    width: 80rem;
    height: 3rem;
    border-radius: 22px 22px 22px 22px;
    margin-left: 12rem;
    margin-top: 30px;
    background: rgba(0, 140, 70, 1);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    border: none;
    cursor: pointer;
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: white;
    border-radius: 8px;
    width: 50rem;
    height: 38rem;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    background-color: rgba(242, 255, 244, 1);
}

.popup-header {
    display: flex;
    margin-bottom: 20px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: rgba(34, 183, 56, 1);
}

.head-img{
padding-left: 5px;
}

.custom-head-job{
margin-right: 30rem;
}

.close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: rgba(34, 183, 56, 1);
}

.ver-line {
    width: 800px;
    height: 1px;
    top: 259px;
    left: 1273px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    border: 1px solid;
    background-color: rgba(34, 183, 56, 1);
    border: none;
    outline: none;
}

.image-war {
    padding-top: 15px;
}

.warning-msg {
    display: flex;
}

.main-warning {
    padding-left: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(193, 81, 0, 1);
}

.popup-body {
    padding-top: 15px;
}

.body {
    padding-top: 14px;
    display: flex;
    padding-bottom: 15px;
}

.suggestion {
    display: flex;
    width: 11rem;
    height: 2rem;
    background-color: rgba(27, 158, 66, 1);
    border-radius: 27px 27px 27px 27px;
}

.image-sug {
    padding-left: 10px;
    padding-top: 2px;

}

.suggestion-main {
    color: rgba(255, 255, 255, 1);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    width: 167.24px;
    height: 40.38px;
    padding-left: 5px;
    padding-top: 3px;
}

.copy {
    display: flex;
    width: 7rem;
    height: 2rem;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 27px 27px 27px 27px;
    margin-left: 30rem;
}

.image-copy {
    padding-left: 10px;
    padding-top: 2px;
}


.copy-main {
    color: rgba(27, 158, 66, 1);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    width: 167.24px;
    height: 40.38px;
    padding-left: 5px;
    padding-top: 3px;
}

.computer-share {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: rgba(0, 157, 47, 1);
    padding-bottom: 10px;
}

.lead-pega {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: rgba(0, 184, 28, 1);
}

.obj {
    display: flex;
    padding-bottom: 20px;
    padding-top: 15px;
}

.img-text1 {
    padding-left: 3px;
    padding-right: 5px;
}

.tetx-1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: rgba(9, 176, 20, 1);
}


.summary {
    display: flex;
    width: 9rem;
    height: 2rem;
    background-color: rgba(27, 158, 66, 1);
    border-radius: 27px 27px 27px 27px;
    margin-top: 25px;
    margin-bottom: 15px;
}

.image-summary {
    padding-left: 10px;
    padding-top: 2px;

}

.summary-main {
    color: rgba(255, 255, 255, 1);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    width: 167.24px;
    height: 40.38px;
    padding-left: 5px;
    padding-top: 3px;
}

.at-last {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: rgba(27, 158, 66, 1);
    margin-top: 10px;
}

.job-percent-outer{
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

.job-percent-inner{
    width: 60%;
    height: 60%;
    background-color: #f7f7f7;
    border-radius: 50%;
}

.resume-analysis-main{
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.job-analysis-pointers{
    max-height: 500px; 
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none;
}

.job-percent{
    padding: 40px;
    width: 80%;
    gap: 20px;
    text-align: center;
}
/* .job-percent-main{
    width: ;
} */

.domain-head{
    padding: 10px;
    gap: 30px;
}
.domain-card{
    margin: 5px;
    gap: 5px;
}

.job-title{
    font-size: 15px;
    font-weight: 600;
}

.job-analysis-pointers::-webkit-scrollbar, .resume-analysis-main::-webkit-scrollbar {
    display: none; /* Hides scrollbar in Chrome, Safari */
}

@media only screen and (min-width: 700px) and (max-width: 900px) {

    .main-jobs {
        display: flex;
        flex-direction: column;
    }

    .exists-jobs {
        width: 350px;
        margin-bottom: 30px;
    }

    .select-can {
        width: 289px;
        margin-left: 28px;
    }

    .search-jobs {
        width: 294px;
        margin-left: 25px;
    }

    .img1 {
        margin-left: 7rem;
    }

    .custom-job {
        width: 350px;
    }

    .candidate-select {
        width: 289px;
        margin-left: 28px;
    }

    .next {
        width: 294px;
        margin-left: 25px;
    }

    .img2 {
        margin-left: 7rem;
    }


    .custom-job-search {
        /* background-color: red; */
        width: 31rem;
        margin: 4rem 6rem;
    }

    .img2-custom {
        margin-left: 12rem;
    }

    .candidate-select-custom{
        margin-left: 5rem;
    }

    .write-content {
        margin: 2rem 6rem;
        width: 22rem;
    }

    .compare-btn {
        width: 23rem;
        height: 3rem;
        margin-left: 5rem;
    }
}


@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .analysis-container{
        flex-direction: column;
    }
    .analysis-container>div{
        width: 100%;
    }
    .exists-jobs {
        width: 350px;
    }

    .select-can {
        width: 289px;
        margin-left: 28px;
    }

    .search-jobs {
        width: 294px;
        margin-left: 25px;
    }

    .img1 {
        margin-left: 7rem;
    }

    .custom-job {
        width: 350px;
    }

    .candidate-select {
        width: 289px;
        margin-left: 28px;
    }

    .next {
        width: 294px;
        margin-left: 25px;
    }

    .img2 {
        margin-left: 7rem;
    }


    .custom-job-search {
        /* background-color: red; */
        width: 51rem;
        margin: 4rem 2rem;
    }

    .img2-custom {
        margin-left: 22rem;
    }

    .candidate-select-custom{
        margin-left: 15rem;
    }

    .write-content {
        margin: 2rem 16rem;
        width: 22rem;
    }

    .compare-btn {
        width: 43rem;
        margin-left: 5rem;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {

    .custom-job-search {
        /* background-color: red; */
        width: 67rem;
        margin: 4rem 2rem;
    }

    .img2-custom {
        margin-left: 30rem;
    }

    .candidate-select-custom{
        margin-left: 22rem;
    }

    .write-content {
        margin: 2rem 12rem;
        width: 42rem;
    }

    .compare-btn {
        width: 62rem;
        margin-left: 2rem;
    }
}



@media only screen and (min-width: 1601px) and (max-width: 1800px) {

    .custom-job-search {
        /* background-color: red; */
        width: 99rem;
        margin: 4rem 2rem;
    }

    .img2-custom {
        margin-left: 48rem;
    }

    .candidate-select-custom{
        margin-left: 40rem;
    }

    .write-content {
        margin: 2rem 30rem;
        width: 42rem;
    }

    .compare-btn {
        width: 97rem;
        margin-left: 2rem;
    }
}



@media only screen and (min-width: 1801px) and (max-width: 1970px) {

    .custom-job-search {
        /* background-color: red; */
        width: 110rem;
        margin: 4rem 2rem;
    }

    .img2-custom {
        margin-left: 55rem;
    }

    .candidate-select-custom{
        margin-left: 47rem;
    }

    .write-content {
        margin: 2rem 27rem;
        width: 71rem;
    }

    .compare-btn {
        width: 114rem;
        margin-left: 2rem;
    }
}

@media only screen and (min-width: 1971px) and (max-width: 2100px) {

    .custom-job-search {
        /* background-color: red; */
        width: 118rem;
        margin: 4rem 2rem;
    }

    .img2-custom {
        margin-left: 55rem;
    }

    .candidate-select-custom{
        margin-left: 47rem;
    }

    .write-content {
        margin: 2rem 27rem;
        width: 71rem;
    }

    .compare-btn {
        width: 114rem;
        margin-left: 2rem;
    }
}
    