.f-screen-popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    justify-content: center;
    align-content: center;
    align-items: center;
    animation: 0.5s ease-out 0s 1 popupbg;
    z-index: 10;
}
.f-screen-popup .popup-cont {
    background: linear-gradient(98.5deg, #87e7a163 53.12%, #3fbf62d4 155.43%);
    border-radius: 16px;
    padding: 30px 30px;
    color: #242424;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    top: 0%;
    animation: 1s ease-out 0s 1 popupcont;
    left: 0%;
    width: 560px;
    transform: translate(0%, 0%);
    box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
}

.f-screen-popup .popup-cont .close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 24px;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    rotate: 45deg;
    cursor: pointer;
    background: #fff;
    animation: 2s ease-out 0s 1 popupclose;
    border-radius: 100px;
}
.f-screen-popup .popup-cont h3 {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #0c8e2f;
}
@keyframes popupbg {
    0% {
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
    }
    100% {
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }
}

.reset-main{
    width: 100%;
    height: 100vh;
    background-color: #fcfcfc;
}
.reset-container{
    width: 500px;
    height: fit-content;
    padding: 30px 25px 30px 25px;
    gap: 15px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
}
.text-container{
    background-color: transparent !important;
    text-align: center;
    line-height: 38px;
}

label{
    font-size: 13px;
    color: #909090;
}

.inputField, input{
    background-color: white !important;
    border-radius: 5px;
    border-width: 1px;
}

.reset-input{
    padding: 10px;
    border: solid 1px #c9c9c9;
    margin-top: 5px;
    outline: none;
}

.reset-btn{
    width: 100%;
    /* text-align: center !important; */
    padding: 10px;
    background-color: #1f3487;
    color: white;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.error{
    color: red;
    font-size: 13px;
}

input::placeholder {
    color: gray; /* Change to any color */
    opacity: 1; /* Make sure it's fully visible */
  }
  
  /* For better browser support */
  input::-webkit-input-placeholder {
    color: gray;
  }
  input:-moz-placeholder {
    color: gray;
  }
  input::-moz-placeholder {
    color: gray;
  }
  input:-ms-input-placeholder {
    color: gray;
  }
.input-container-3{
    width: 80% !important;
}

.input-3{
    width: 23% !important;
}

.head{
    font-size: 13px;
}

.login-input{
    margin: 5px 0px 5px 0px;
}

.error{
    color: red;
}

.heading{
    color: #2d5ab4 !important;
}
