@media (max-width: 768px) {

.main-dash .content .bench-cards {
    padding: 18px 25px;
    background: rgba(68, 183, 148, 0.21);
    gap: 8px;
    border-radius: 16px;
    width: calc(47% - 38px);
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease; /* Smooth transition */
}

.main-dash .content .bench-cards:hover {
    transform: translateY(-10px); /* Move card slightly up */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow */
}

.main-dash .content .bench-cards:hover .details {
    color: #1fc79c; /* Change text color on hover */
}

.main-dash .content .bench-cards .details {
    gap: 10px;
    color: #025042;
    transition: color 0.3s ease; /* Transition for text color */
}

.main-dash .content .bench-cards .Overlay .view-btn {
    display: none;
}
.main-dash .content .bench-cards:hover .Overlay .view-btn:hover {
    transform: scale(1.1);
}
.main-dash .content .bench-cards:hover .Overlay .view-btn.delete {
    background: #f1d9d9;
    & i{
        color: rgba(255, 17, 17, 0.736);
    }
}
.main-dash .content .bench-cards:hover .Overlay .view-btn {
    border-radius: 100px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background: #d2d8ec;
    box-shadow: #0000003d 0px 0px 6px 0px;
    border: none;
    margin: 0 10px;
    cursor: pointer;
}
.main-dash .content .bench-cards:hover .Overlay {
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    height: 100%;
    background: linear-gradient(to bottom right, rgb(230, 243, 238),rgb(239, 247, 243), rgb(213, 235, 227));
    z-index: 10;
    box-shadow: #00000017 0px 0px 12px 5px;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.main-dash .content .bench-cards .Overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    border-radius: 100%;
    width: 0;
    height: 0;
}
.main-dash .content .bench-row {
    gap: 32px;
    box-sizing: border-box;
    flex-wrap: wrap;
}
.main-dash .content .bench-cards .details {
    gap: 10px;
    color: #025042;
}
.main-dash .content .bench-cards .details .object {
    text-wrap: nowrap;
}

/* --               Register Content                -- */

.main-dash .content .registerForm {
    background: #ffffff;
    box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
    padding: 25px 30px;
    border-radius: 16px;
    gap: 24px;
    .registerTopHead h4 {
        font-size: 20px;
        font-weight: 600;
        color: rgb(34 157 74);
    }
    .formInner {
        gap: 16px;
    }
    .formInner .inputRow {
        width: 100%;
        gap: 10px;
        input, .input { width: 100%;
            box-sizing: border-box;
        }
    }
    .formInner .registerCandidate {
        margin-right: auto;
        padding: 6px 10px;
        background: #1fc79c;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        border: none;
    }
    .error {
        color: red;
    }
    .formInner .input {
        font-size: 16px;
        span {
            color: rgb(34 157 74);
            font-weight: 500;
            line-height: 1;
            font-size: 15px;
            margin-left: 8px;
            margin-bottom: -8px;
            background: #ffffff;
            margin-right: auto;
            z-index: 10;
            padding: 0px 8px;
            .asterisk {
                color: #ea3333;
                padding: 0;
                margin-left: 0;
            }
        }
        input, textarea, select {
            border: 3px solid rgba(34, 157, 75, 0.24);
            background: transparent;
            padding: 10px 16px;
            color:forestgreen;
        }
    }
}
.modal-f-popup.wide .modal-content {
    width: 660px;
}
.modal-f-popup .form-outer .save-modal {
    margin-top: 12px;
    padding: 6px;
    color: #fff;
    background: #12c99d;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
}
.modal-f-popup .form-outer {
    gap: 12px;
}
.modal-f-popup, .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(5px);
    justify-content: center;
    align-content: center;
    align-items: center;
    animation: 0.5s ease-out 0s 1 popupbg;
    z-index: 10;
}
.modal-f-popup .row-flex.input-row .object {
    text-wrap: nowrap;
    font-size: 14px;
}

.modal-f-popup .row-flex.input-row input, .modal-f-popup .row-flex.input-row select  {
    background: transparent;
    border: none;
    border-bottom: 1px solid #7cbcaa;
    outline: none;
    width: calc(100% - 20px);
}
.modal-f-popup .row-flex.input-row {
    gap: 9px;
    width: 49%;
}
.modal-f-popup .modal-content, .modal-overlay .modal-content {
    /* background: linear-gradient(128deg, #c5ffdbcc, #f9fffca6, #a7f1c3); */
    background: #e5f7edcc;
    border-radius: 16px;
    padding: 30px 30px;
    color: #025042;
    font-size: 18px;
    font-weight: 400;
    gap: 6px;
    position: relative;
    top: 0%;
    animation: 1s ease-out 0s 1 popupcont;
    left: 0%;
    width: 90%;
    transform: translate(0%, 0%);
    box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
    .left-tab {
        width: calc(100% - 344px);
        max-width: 80%;
        margin-right: 24px;
    }
    .right-tab {
        width: 320px;
        overflow: auto;
    }
    .card-container {
        flex-wrap: wrap;
        gap: 8px;
        max-height: 400px;
        align-content: flex-start;
        overflow-x: auto;
        overflow-y: unset;
    }
    .card-container {
        overflow-y: scroll;
        scrollbar-width: thin; /* For Firefox */
        scrollbar-color: rgba(0, 0, 0, 0.3) transparent; /* For Firefox */
        border-radius:5px;
      }
      
      /* For Webkit browsers (Chrome, Safari) */
      .card-container::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
        border-radius:5px;
      }
      
      .card-container::-webkit-scrollbar-track {
        background: transparent; /* Track of the scrollbar */
        border-radius:5px;
      }
      
      .card-container::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3); /* Thumb of the scrollbar */
        border-radius: 4px; /* Rounded corners for a smoother look */
      }
      
      .card-container::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.5); /* Darker on hover */
      }      
    .card-container.comment {
        flex-wrap: unset;
        max-height: 335px;
        min-height: 335px;
        padding: 16px 12px;
        box-sizing: border-box;
        min-height: 320px;
        overflow-y: auto;
        overflow-x: auto;
        background: #fff;
        .submission-card {
            width: 250px;
        }
    }
    .submission-card {
        width: 200px;
        padding: 8px 12px;
        background:#12c99e22;
        border-radius: 10px;
        order: 2;
        transition: none;
        min-height: 101px;
        &.active{
            width: 300px;
            min-height: 351px;
            order: 0;
        }
    }
    .submission-card .view {
        display: block;
    }

    .submission-card .active-view {
        display: none;
    }
    .submission-card.active .active-view {
        display: block;
    }
    .comment-section {
        margin-top: 10px;
        gap: 12px;
        input {
            width: 400px;
            border: none;
            padding: 8px 12px;
            font-size: 15px;
            border-radius: 10px;
            background:#c5e9e0;
            color: #298b5a;
        }
        button {
            border: none;
            font-size: 15px;
            padding: 8px 14px;
            border-radius: 14px;
            background: #2e9f67;
            color: #fff;
            font-weight: 400;
        }
    }
    .close-modal{
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 24px;
        height: 35px;
        width: 35px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        rotate: 45deg;
        cursor: pointer;
        background: #fff;
        animation: 2s ease-out 0s 1 popupclose;
        border-radius: 100px;
        border:none;
    }
    h3, h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 12px;
        margin-top: 0px;

    }
    p {
        font-size: 15px;
        margin: 3px 0px;
    }
    .details {
        gap: 20px;
    }
}
@keyframes popupbg {
    0% {
        backdrop-filter: blur(0px);
    }
    100% {
        backdrop-filter: blur(5px);
    }
}
@keyframes popupcont {
    0% {
        top: -100%;
    }
    100% {
        top: 0%;
    }
}
@keyframes popupclose {
    0% {
        rotate: 0deg;
    }
    50% {
        rotate: 0deg;
    }
    100% {
        rotate: 45deg;
    }
}

.filterBg {
    margin: 16px 15px 0 15px;
    padding: 14px 30px;
    background: #9ae2912c;
    border-radius: 16px;
    .filters {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        input, select {
            background: transparent;
            width: 80%;
            border: 3px solid rgba(34, 157, 75, 0.24);
            padding: 6px 16px;
            border-radius: 12px;
            color: forestgreen;
        }
    }
}
.benchhead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.new-candidates:nth-child(2) {
    color: #2aaa8b;
    cursor:default;
}
.new-candidates span {
    color: #2aaa8b;
}
.new-candidates {
    /* background: #fff; */
    border-radius: 16px;
    padding: 15px 20px;
    width: fit-content;
    margin: 20px 21px 6px 21px;
    gap: 16px;
    align-items: center;
    font-size: 18px;
    color: #4f65b4;
    font-weight: 600;
    cursor: pointer;
    img{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.addCandidate {
    background-color: #E4F0E2;
    border:4px solid #B5DDBE;
    padding: 0.5rem;
}
.filterBg .head {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #2aaa8b;
}

/* animations---------- */

@keyframes fadeIn {
    0% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0);
    }
}

.main-dash .content .bench-cards {
    animation: fadeIn 0.5s ease-in-out;
}
@keyframes popupcont {
    0% {
        top: -100%;
        transform: scale(0.8); /* Start smaller */
    }
    50% {
        top: 0%;
        transform: scale(1.05); /* Slight overshoot for bounce */
    }
    100% {
        top: 0%;
        transform: scale(1); /* Settle into place */
    }
}

.modal-f-popup .modal-content {
    animation: popupcont 0.8s ease-out;
}
.close-modal {
    transition: transform 0.3s ease;
}

.close-modal:hover {
    transform: rotate(90deg); /* Rotate more on hover */
}
.filterBg .filters input:focus, 
.filterBg .filters select:focus {
    border: 3px solid #34a853;
    box-shadow: 0 0 8px rgba(52, 168, 83, 0.5); /* Green glow */
    transition: all 0.3s ease;
}
.main-dash .content .bench-cards:nth-child(1) {
    animation: fadeIn 0.5s ease-in-out 0.1s;
}

.main-dash .content .bench-cards:nth-child(2) {
    animation: fadeIn 0.5s ease-in-out 0.3s;
}

.main-dash .content .bench-cards:nth-child(3) {
    animation: fadeIn 0.5s ease-in-out 0.5s;
}
.main-dash .content .registerCandidate:hover {
    background: #1aa683;
    transform: scale(1.05); /* Slight enlargement */
    box-shadow: 0 4px 10px rgba(26, 166, 131, 0.3); /* Add shadow */
    transition: all 0.3s ease;
}

.fas {
    margin-right: 5px;
    font-size: 18px;
    color: green;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth color change and scaling on hover */
}

.active-bg {
    background-color: green;
}

.innerContent {
    .candidates-table {
        width: 100%;
        height: calc(100vh);
        background: #ffffff;
        border-radius: 10px;
        color: #3a753f;
        border-collapse: collapse;
        position: relative;
        line-height: 1.4em;
        animation: fadeInTable 0.5s ease-in-out; /* Fade-in animation on render */
        
        td {
            text-align: center;
            padding: 0.5rem 0.2rem;
            transition: background-color 0.3s ease-in-out, transform 0.2s, box-shadow 0.3s ease; /* Smooth transition for hover and shadow */
            
            button {
                cursor: pointer;
                border: none;
                background: transparent;
                transition: transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Smooth scaling, color change, and shadow on hover */
            }
            
            &:hover {
                background-color: #f3f3f3;
                transform: scale(1.02); /* Slight scaling on hover */
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
                animation: pulse 0.5s ease; /* Pulse effect on hover */
            }
        }
    }

    .candidates-table thead {
        background: #def1d9;
        position: sticky;
        top: 0;
        animation: fadeInHeaders 0.6s ease-in-out; /* Fade-in for headers */
        
        th {
            padding: 1rem 0.6rem;
            font-weight: 600;
        }
    }
}


.candidates-table .ov-data {
    height: 100%;
    
    .table-head-data {
        background: #def1d9;
        width: max-content;
        width: 100%;
        
        .top-head-data {
            padding: 1rem 0.6rem;
            font-weight: 600;
            text-align: center;
        }

        .top-head-data:nth-child(1) { min-width: 250px; max-width: 250px; }
        .top-head-data:nth-child(2) { min-width: 260px; max-width: 260px; }
        .top-head-data:nth-child(3) { min-width: 170px; max-width: 170px; }
        .top-head-data:nth-child(4) { min-width: 150px; max-width: 150px; }
        .top-head-data:nth-child(5) { min-width: 170px; max-width: 170px; }
    }

    .table-data {
        width: max-content;
        border-bottom: 1px solid #e8e8e8;
        cursor: pointer;
        font-weight: 400;
        text-align: center;
        transition: background-color 0.3s ease-in-out, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for background color, scale, and shadow */

        .items-data {
            padding: 1rem 0.6rem;
            margin-bottom: 10px;
            button {
                border: none;
                background: #def1d9;
                padding: 9px 15px 6px 15px;
                border-radius: 10px;
                cursor: pointer;
                transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth scale and shadow */
                &:last-child {
                    margin-left: 10px;
                    background: #f1d9d9;
                    i {
                        color: rgba(255, 17, 17, 0.736);
                    }
                }
                &:hover {
                    transform: scale(1.1);
                    box-shadow: 0px 0px 12px #d8edd9;
                    animation: bounce 0.6s ease; /* Bounce effect on hover */
                }
            }

            &:hover {
                background-color: #f3f3f3;
                transform: translateY(-3px); /* Slight upward movement on hover */
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
                animation: pulse 0.5s ease; /* Pulse effect on hover */
            }

            &:nth-child(1).address { min-width: 100%; max-width: 100%; }
            &:nth-child(1) { min-width: 250px; max-width: 250px; }
            &:nth-child(2) { min-width: 260px; max-width: 260px; }
            &:nth-child(3) { min-width: 170px; max-width: 170px; }
            &:nth-child(4) { min-width: 150px; max-width: 150px; }
            &:nth-child(5) { min-width: 170px; max-width: 170px; }
        }
    }
}


.fas {
    margin-right: 5px;
    font-size: 18px;
    color: green;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth color change and scaling on hover */
}

.icon-wrong {
    font-size: 18px;
    color: red;
}

.InActive {
    color: red;
}

.change-status {
    display: "flex"; 
    align-items: "center";
    justify-content: center;
}

.change-status .fas {
    border: 1px solid white;
    padding: 4px;
    border-radius: 10%;
    background-color: white;
}
.sidebar {
  width: 300px;
  /* background-color: #DEF1D9; */
  color: purple;
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  text-align: center;
}

.candidates-table .details .table-data {
    text-align: left;
}
.candidates-table .details {
    width: 100%;
    padding: 20px;
    /* background-color: #DEF1D9; */
    color: purple;
    height: 100vh;
    border-top: 1px solid gray;
        .items-data.details-data {
            &:nth-child(1).address { min-width: 50%; max-width: 50%; }
            &:nth-child(1) { min-width: 50%; max-width: 50%; }
            &:nth-child(2) {min-width: 50%; max-width: 50%; }
            &:nth-child(3) { min-width: 50%; max-width: 50%; }
            &:nth-child(4) { min-width: 50%; max-width: 50%; }
            &:nth-child(5) {min-width: 50%; max-width: 50%; }
        }
  }
  

  .main-data{
    color: purple;
  }

  .bg-g {
    /* background-color:#DEF1D9; */
  }


  .tap-data {
    display: flex;
    flex-direction: column;
    gap: 15px; 
    padding: 15px;
  }
  
  .tap-data h3 {
    margin-bottom: 10px; /* Space between the heading and the first div */
  }
  
  .tap-data > div {
    margin-bottom: 10px; /* Space between each inner div */
  }

.candidates-details-menu-button.items-data{
    padding: 1rem 0.6rem;
    margin-bottom: 10px;
    margin-left: auto;
    &:hover {
        background-color: transparent !important;
        transform: unset !important;
        box-shadow: unset !important;
    }
    button {
        background: none;
    border: none;
    padding: 5px 10px; 
    }
    button.delete-btn{
        i{
            color: red;
        }
    }
}

.main-dash .content .innerContent.candidate-det {
    max-height: calc(100vh);
}

.candidates-table {
    overflow: scroll;
}

}


/* Iframe Modal CSS Styling */

.iframeModal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 33;
    display: flex;
    background-color: rgba(18, 18, 19, 0.571);
    
}
/* view file css */
.iFrame-properties{
    height: 80%;
    width: 80%;
    
    /* height: 800px; */
   margin: auto;
  
}

iframe > img{
    width: 200px !important;
}

.iframeClose-btn{
    width: fit-content;
    height: 30px;
    position: absolute;
    right: 0;
}

.fileInput{
    position: absolute;
    width: 140px;
    height: 140px;
    padding: 20px;
    top: 0;
    left: 0;
}
.p-relative {
    position: relative;
}