.login-SignUp {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    .login-inner {
        position: relative;
        height: 100vh;
        width: 100%;
        .bgImage {
            width: 100%;
            height: 100vh;
            max-width: 100%;
            max-height: 100vh;
            min-width: 100%;
            object-fit: cover;
            min-height: 100vh;
            filter: blur(0.1);
        }

        .log-sign-frm.active {
            right: 0%;
            position: absolute;
            p.error {
                margin: -16px 0 -3px 0;
            }
        }
        .log-sign-frm {
            gap: 18px;
            display: flex;
            flex-direction: column;
            position: fixed;
            top: 10vh;
            right: -100%;
            width: 400px;
            background: #fff;
            padding: 36px 45px;
            transform: translate(-20%, -3%);
            transition: all 0.6s ease-in-out;
            .button-row {
                gap: 12px;
                .login-button {
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                    background: #1F3487;
                    width: 100%;
                    border-radius: 4px;
                    padding: 10px 10px;
                    text-align: center;
                    border: none;
                }
                .signup-button {
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;width: 100%;
                    border-radius: 4px;
                    padding: 10px 10px;
                    text-align: center;
                    border: none;
                    background: #1F8768;
                }
            }
            .login-frm {
                gap: 15px;
                .forgotLink {
                    margin-top: 10px;
                    color: #1F8768;
                }
                .multi-field {
                    gap: 14px;
                    .login-input {
                        width: calc(100% - 6px);
                        font-size: 15px;
                        padding: 4px 0 8px 0;
                        margin-bottom: 10px;
                    }
                }
            }
            .contact-bot {
                margin-top: -10px;
                margin-bottom: 1px;
            }
            .country-code-select {
                height: 35.3px;
                border: none;
                margin-right: 10px;
                width: 100%;
                font-size: 15px;
                outline: none;
            }
            .login-title {
                font-size: 32px;
                font-weight: 600;
                margin: 0;
                letter-spacing: 0.4px;
                line-height: 1;
            }
            .subtitle {
                font-size: 28px;
                color: #1F8768;
                letter-spacing: 0.4px;
                font-weight: 600;
                margin: 0;
                line-height: 1;

            }
            .input {
                gap: 0;
                letter-spacing: 0.4px;
                .head {
                    font-size: 14px;
                    color: #777;
                    font-weight: 400;
                    color: #808080;
                    font-weight: 400;
                }
                .login-input {
                    font-size: 16px;
                    font-weight: 600;
                    color: #808080;
                    padding: 7px 0 13px 0;
                    border: none;
                    border-bottom: 0.8px solid #ccc;
                    outline: none;
                }
                .full-width{
                    width: 100%;
                }
                .password-input-wrapper {
                    display: flex;
                    align-items: center;
                    background: transparent;
                    border: none;
                    flex-direction: row;
                    justify-content: space-between;
                    .password-toggle {
                        border: none;
                        background: transparent;
                        margin-left: -30px;
                    }
                }
            }
        }
        
    }
}
.login-button {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.login-button .loader.active {
    display: block;
}
.login-button .loader {
    width: 25px;
    height: 25px;
    animation: loginLoader 4s infinite;
    display: none;
}
@keyframes loginLoader {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}