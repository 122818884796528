/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

/* Modal Box */
.modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 400px;
    animation: fadeIn 0.3s ease-in-out;
}

/* Modal Heading */
.modal h2 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #333;
}

/* Modal Text */
.modal p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #555;
}

/* Button Group */
.button-group {
    display: flex;
    gap: 15px;
    justify-content: center;
}

/* Continue Button */
.continue-btn {
    background: #007bff;
    color: white;
    padding: 6px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.2s ease-in-out;
}

.continue-btn:hover {
    background: #0056b3;
}

/* Logout Button */
.logout-btn {
    background: #dc3545;
    color: white;
    padding: 6px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.2s ease-in-out;
}

.logout-btn:hover {
    background: #c82333;
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
