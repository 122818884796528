@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');

.innerContent {
    .candidates-table {
        width: 100%;
        height: calc(100vh - 226px);
        background: #ffffff;
        border-radius: 10px;
        color: #3a753f;
        border-collapse: collapse;
        position: relative;
        line-height: 1.4em;
        animation: fadeInTable 0.5s ease-in-out; /* Fade-in animation on render */
        
        td {
            text-align: center;
            padding: 0.5rem 0.2rem;
            transition: background-color 0.3s ease-in-out, transform 0.2s, box-shadow 0.3s ease; /* Smooth transition for hover and shadow */
            
            button {
                cursor: pointer;
                border: none;
                background: transparent;
                transition: transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Smooth scaling, color change, and shadow on hover */
            }
            
            &:hover {
                background-color: #f3f3f3;
                transform: scale(1.02); /* Slight scaling on hover */
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
                animation: pulse 0.5s ease; /* Pulse effect on hover */
            }
        }
    }

    .candidates-table thead {
        background: #def1d9;
        position: sticky;
        top: 0;
        animation: fadeInHeaders 0.6s ease-in-out; /* Fade-in for headers */
        
        th {
            padding: 1rem 0.6rem;
            font-weight: 600;
        }
    }
}

.submitted-to-vendor {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
    margin-top: 10px;
    animation: fadeIn 0.5s ease-in-out; /* Fade-in animation for the element */
}

.submitted-to-vendor .fa-check-circle {
    color: #4CAF50;
    margin-right: 8px;
    font-size: 18px;
    transition: color 0.3s ease; /* Smooth color transition */
}

.fa-info-circle:before {
    color: #379d50;
    font-size: 18px;
}

.rate {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.rate .fas {
    margin-right: 5px;
    font-size: 18px;
    color: green;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth color change and scaling on hover */
}

.name-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.name-with-icon .fas.fa-user {
    margin-right: 5px;
    color: #659b5bc0;
    transition: color 0.3s ease; /* Smooth color transition */
}

.select-field {
    padding: 6px 5px;
    background: linear-gradient(180deg, #ceede15c, #28db9664, #ceede152);
    border: none;
    border-radius: 4px;
    color: #147753;
    transition: background 0.3s ease, transform 0.3s ease, border-color 0.3s ease; /* Smooth background, scale, and border transition */
    
    &:hover {
        background: linear-gradient(180deg, #28db96, #ceede1); /* Color shift on hover */
        transform: scale(1.05); /* Slight scale effect on hover */
    }

    &:focus {
        border: 2px solid #28db96; /* Border color change on focus */
        outline: none; /* Remove default outline */
    }
}

.main-dash .content .filterBg .innerContent {
    padding: unset;
    margin-top: 25px;
}

/* iOS-style scrollbar for .candidates-table .ov */
.candidates-table .ov {
    height: 100%;
    scrollbar-width: thin;
    scrollbar-color: #a8a8a8 #f1f1f1;
    overflow: auto;
    scroll-behavior: smooth; /* Enables smooth scrolling */
    animation: slideInScroll 0.5s ease; /* Smooth scrolling animation */
    
    /* WebKit Scrollbar */
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #a8a8a8;
        border-radius: 10px;
        border: 2px solid #f1f1f1;
        transition: background 0.3s ease; /* Smooth background transition */
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888888;
    }

    .table-head {
        background: #def1d9;
        width: max-content;
        
        .top-head {
            padding: 1rem 0.6rem;
            font-weight: 600;
            text-align: center;
        }

        .top-head:nth-child(1) { min-width: 250px; max-width: 250px; }
        .top-head:nth-child(2) { min-width: 190px; max-width: 190px; }
        .top-head:nth-child(3) { min-width: 320px; max-width: 320px; }
        .top-head:nth-child(4) { min-width: 350px; max-width: 350px; }
        .top-head:nth-child(5) { min-width: 100px; max-width: 100px; }
        .top-head:nth-child(6) { min-width: 200px; max-width: 200px; }
        .top-head:nth-child(7) { min-width: 220px; max-width: 220px; }
        .top-head:nth-child(8) { min-width: 170px; max-width: 170px; }
    }

    .table-data {
        width: max-content;
        border-bottom: 1px solid #e8e8e8;
        cursor: pointer;
        font-weight: 400;
        text-align: center;
        transition: background-color 0.3s ease-in-out, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for background color, scale, and shadow */

        .items {
            padding: 1rem 0.6rem;
            button {
                border: none;
                background: #def1d9;
                padding: 9px 15px 6px 15px;
                border-radius: 10px;
                cursor: pointer;
                transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth scale and shadow */
                &:last-child {
                    margin-left: 10px;
                    background: #f1d9d9;
                    i {
                        color: rgba(255, 17, 17, 0.736);
                    }
                }
                &:hover {
                    transform: scale(1.1);
                    box-shadow: 0px 0px 12px #d8edd9;
                    animation: bounce 0.6s ease; /* Bounce effect on hover */
                }
            }

            &:hover {
                background-color: #f3f3f3;
                transform: translateY(-3px); /* Slight upward movement on hover */
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
                animation: pulse 0.5s ease; /* Pulse effect on hover */
            }

            &:nth-child(1) { min-width: 250px; max-width: 250px; }
            &:nth-child(2) { min-width: 190px; max-width: 190px; }
            &:nth-child(3) { min-width: 320px; max-width: 320px; }
            &:nth-child(4) { min-width: 350px; max-width: 350px; }
            &:nth-child(5) { min-width: 100px; max-width: 100px; }
            &:nth-child(6) { min-width: 200px; max-width: 200px; }
            &:nth-child(7) { min-width: 220px; max-width: 220px; }
            &:nth-child(8) { min-width: 170px; max-width: 170px; }
        }
    }
}

/* Keyframes for animation */
@keyframes fadeInTable {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInHeaders {
    0% { opacity: 0; transform: translateY(-20px); }
    100% { opacity: 1; transform: translateY(0); }
}

@keyframes slideInScroll {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.03); }
    100% { transform: scale(1); }
}

@keyframes bounce {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
