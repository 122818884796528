.MainLoading {
    position: fixed;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    z-index: 9999;
}
/* Style for the loading container */
.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 40px 60px;
    backdrop-filter: blur(6px);
    border-radius: 8px;
    z-index: 9999;
  }
  
  /* Style for the spinner */
  .spinner p {
    margin: 0;
    margin-top: 13px;
  }
  .spinner {
    border: 4px solid #f3f3f3; /* Light gray background */
    border-top: 4px solid #3498db; /* Blue color for the top */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 0.6s linear infinite;
  }
  
  /* Keyframes for the spinning animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  