/* .w-100{
    width: 100%;
    background-color: transparent !important;
} */

.h-100{
    height: 100%;
}

.w-50{
    width: 50%;
}

.h-50{
    height: 50%;
}

.d-flex{
    display: flex;
}

.flex-row{
    flex-direction: row;
}

.flex-column{
    flex-direction: column;
}

.justify-center{
    justify-content: center;
}

.justify-between{
    justify-content: space-between;
}

.justify-evenly{
    justify-content: space-evenly;
}

.justify-around{
    justify-content: space-around;
}

.align-start{
    align-items: start;
}

.align-end{
    align-items: end;
}

.align-center{
    align-items: center;
}

.text-center{
    text-align: center;
}
.text-end{
    text-align: end;
}

.cursor-pointer{
    cursor: pointer;
}

.text-underline{
    text-decoration: underline;
}
.heading-3{
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: #1f3487;
}

.text-2{
    font-size: 15px;
    color: rgb(31, 31, 31);
    line-height: 30px;
}

.text-3{
    font-size: 13px;
    color: #909090;
}

.text-4{
    font-size: 17px;
    line-height: 35px;
    font-weight: 400;
    color: #777676;
}

.text-5{
    font-size: 13px;
    line-height: 35px;
}

.b-test{
    border: solid 1px black;
}
.gap-10{
    gap: 30px;
}

.bg-transparent{
    background-color: transparent !important;
}