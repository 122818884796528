.activeIntvRegister {
    padding: 20px;
    background: #f2f2f2;
    flex-grow: 1;
    font-family: "Poppins";
    font-weight: 600;
    max-height: 100%;


    .header-active {
        display: flex;
        align-items: center;
        gap: 10px;

        :nth-child(1) {
            margin-right: 10px;
        }

        .title {
            font-size: 20px;
            color: #1C1C63;
        }
    }

    .mainContent-activeIntv {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .legendHeading-activeIntv {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        font-weight: 500;
        color: #5390C2;
        padding-bottom: 10px;

        img {
            border-radius: 50%;
            padding: 5px;
            background-color: #48A9f8;
        }

        .divider {
            width: 100%;
            border-bottom: 1px solid #C9C9C9;
        }

        .divider-intv {
            width: 85%;
            border-bottom: 1px solid #C9C9C9;
        }

        .divider1 {
            width: 85%;
            border-bottom: 1px solid #C9C9C9;
        }

        .divider5 {
            width: 80%;
            border-bottom: 1px solid #C9C9C9;
            color: rgba(60, 85, 186, 1);

        }

        .cont-name {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 12px;
            text-align: left;
            color: rgba(60, 85, 186, 1);

        }

    }


    .gridActiveIntv {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 30px;


        .col-span-2 {
            grid-column: 1 / 3;
        }

        .col-span-3 {
            grid-column: 1 / 4;
        }
    }

    .inputComponentActiveIntv {
        display: flex;
        gap: 5px;
        flex-direction: column;
        font-weight: 400;
        border-bottom: 1px solid #BDBDBD;

        label {
            font-size: 13px;
            color: #909090;
        }

        input,
        select {
            display: block;
            color: #bdbdbd;
            width: 100%;
            border: none;
            font-size: 15px;
            font-weight: 600;
            background-color: transparent;
            margin-bottom: 5px;
            text-overflow: ellipsis;
            color: #494E63;
            border: none;
            outline: none;
            /* &:focus {
                border: none;
                outline: none;
                font-style: bold;
                color: black;
            } */
        }

    }

    .saveBtnIntv {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1BD084;
        border-radius: 27px;
        gap: 5px;
        padding: 13px 17px;

        margin-top: 15px;
        color: #fff;
        border: none;
        box-shadow: 0px 0px 10px 0px #0C6A425E;

        img {
            padding: 2px;
            width: 16px;
            height: 16px;

            background: #fff;
            border-radius: 50%
        }
    }

}

.textarea-reason-input-text {
    width: 60%;
    height: 100%;
    /* padding: 10px; */
    /* border: 1px solid #d3c7f3; */
    /* border-radius: 5px; */
    font-size: 14px;
    /* margin-bottom: 5px; */
    font-family: "Arial", sans-serif;
    /* box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); */
    resize: none;
    /* border-bottom: 1px solid #BDBDBD; */
    display: flex;
    gap: 5px;
    flex-direction: column;
    font-weight: 400;
    /* border-bottom: 1px solid #BDBDBD; */
    outline: none;

    label {
        font-size: 13px;
        color: #909090;
    }
}

.textarea-reason{
        width: 23rem;
        height: 5rem;
        background-color: rgb(255, 255, 255);
}

.inputContent {
    display: flex;
    gap: 5px;
    flex-direction: column;
    font-weight: 400;
    /* border-bottom: 1px solid #BDBDBD; */

    label {
        font-size: 13px;
        color: #909090;
    }
}

.inputContent1 {
    margin-top: 25px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    font-weight: 400;
    /* border-bottom: 1px solid #BDBDBD; */

    label {
        font-size: 13px;
        color: #909090;
    }
}
